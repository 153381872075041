<template>
  <div class="header">
    <img class="logo" src="../assets/logo.png" />
    <span class="name">LoanNaira</span>
    <div class="nav">
      <router-link :class="{'current': $route.path == '/'}" to="/">Home</router-link>
      <router-link :class="{'current': $route.path == '/about'}" to="/about">About us</router-link>
      <router-link :class="{'current': $route.path == '/faq'}" to="/faq">FAQ</router-link>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    
  }
}
</script>

<style scoped lang="scss">
.header {
  text-align: left;
  width: 100%;
  max-width: 1200px;
  min-height: 60px;
  margin: auto;
  display: flex;
  .logo {
    margin-left: 200px;
    width: 44px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 6px;
  }
  .name {
    font-size: 20px;
    color: #7CF259;
    font-weight: bold;
    margin-right: 80px;
    line-height: 60px;
    font-weight: bold;
  }
  .nav {
    flex: 1;
    text-align: right;
    margin-right: 200px;
    a {
      position: relative;
      display: inline-block;
      text-decoration: none;
      color:#444444;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      font-size: 18px;
      &.current {
        font-weight: bold;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0px;
          height: 2px;
          background-color: #7CF259;
        }
      }
    }
  }
}
</style>