<template>
  <div class="home">
    <div class="top">
      <div class="download">
        <div>
          <div class="tetx">
            ALL YOUR LOAN<br />
            NEEDS IN ONE APP
          </div>
          <div class="button" @click="goDownload" >
            <img src="../assets/download.png" />
            Download LoanNaira
          </div>
        </div>
      </div>
      <img src="../assets/home_main.png" />
    </div>

    <div class="member">
      <div class="content">
        <div class="title">Become a Member of LoanNaira</div>
        <div class="list">
          <div class="item">
            <img src="../assets/member_list_1.png" />
            Download
          </div>
          <div class="item">
            <img src="../assets/member_list_2.png" />
            Apply a loan
          </div>
          <div class="item">
            <img src="../assets/member_list_3.png" />
            Receive your loan
          </div>
          <div class="item">
            <img src="../assets/member_list_4.png" />
            Repay your loan
          </div>
        </div>
      </div>
    </div>

    <div class="services">
      <div class="content">
        <div class="title">To Provide Better Financial Services</div>
        <div class="list">
          <div class="item">
            <img src="../assets/success.png" />
            <div class="text">
              <span>High loan amount:</span>
              Loan amount up to 50,000N
            </div>
          </div>
          <div class="item">
            <img src="../assets/success.png" />
            <div class="text">
              <span>Fast approval:</span>
              Getting a loan in minutes
            </div>
          </div>
          <div class="item">
            <img src="../assets/success.png" />
            <div class="text">
              <span>Paperless apply:</span>
              Application will be processed in minutes with no documentation
            </div>
          </div>
          <div class="download" @click="goDownload">
            <img src="../assets/download_2.png" />
            Download LoanNaira
          </div>
        </div>
      </div>
    </div>

    <div class="choose">
      <div class="content">
        <div class="title">Why Choose LoanNaira</div>
        <div class="list">
          <div class="item">
            <img src="../assets/choose_list_1.png" />
            Fast Loan approval
          </div>
          <div class="item">
            <img src="../assets/choose_list_2.png" />
            Dedicated Team
          </div>
          <div class="item">
            <img src="../assets/choose_list_3.png" />
            24/7 Supports
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <img src="../assets/home_bottom.png" />
      <div class="content">
        <div class="title">Trusted by thousands of Nigerians</div>
        <div class="text">I am glad I found LoanNaira. I was able to get a loan in minutes to solve my business problem and avoid embarrassment. With LoanNaira I no longer need to beg friends and family for soft loan in moments of emergencies. Thank you LoanNaira.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    
  },
  methods: {
    goDownload() {
      window.location.href = 'https://m.iflk.net/apk/loannaira/loannaira-release-1.2.4.apk';
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  .top {
    position: relative;
    .download {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-color:rgba(124,242,89,0.8);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .tetx {
        font-weight: bold;
        font-size: 29px;
        color: white;
        text-align: left;
        display: inline-block;
        margin-right: 22px;
      }
      .button {
        cursor: pointer;
        margin-top: 60px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 25px;
        background-color: white;
        color: #229500;
        margin-right: 38px;
        font-size: 18px;
        img {
          display: inline-block;
          width: 20px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
    img {
      width: 100%;
      display: block;
    }
  }

  .member, .choose {
    background: url('../assets/home_bg_1.png'), #EEFAE2;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .content {
      max-width: 800px;
      margin: 0 auto;
      .title {
        font-size: 24px;
        font-weight: bold;
        padding-top: 60px;
      }
      .list {
        padding-top: 50px;
        padding-bottom: 50px;
        display: flex;
        .item {
          flex: 1;
          font-size: 18px;
          text-align: center;
          img {
            display: block;
            margin: 0 auto;
            width: 120px;
          }
        }
      }
    }
  }

  .services {
    background: url('../assets/home_bg_2.png'), #d4eec1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .content {
      max-width: 800px;
      margin: 0 auto;
      .title {
        font-size: 24px;
        font-weight: bold;
        padding-top: 60px;
      }
      .list {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 50%;
        text-align: right;
        .item {
          text-align: center;
          display: flex;
          text-align: left;
          width: 250px;
          margin: 0 0 0 auto;
          margin-bottom: 30px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 8px;
            vertical-align: middle;
          }
          .text {
            font-size: 18px;
            color: #162F0E;
            span {
              font-size: 20px;
              display: block;
              color: #36C80A;
              font-weight: bold;
            }
          }
        }
        .download {
          cursor: pointer;
          margin-top: 10px;
          margin-bottom: 36px;
          border-radius: 25px;
          background-color: #7CF259;
          color: white;
          font-size: 18px;
          height: 45px;
          line-height: 45px;
          display: inline-block;
          padding-left: 40px;
          padding-right: 40px;
          img {
            display: inline-block;
            width: 20px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .bottom {
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    .content {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50px);
      width: 345px;
      padding: 20px 18px 25px 18px;
      color: #162F0E;
      background: white;
      border-radius: 15px;
      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .text {
        font-size: 18px;
        line-height: 30px;
        text-align: left;
      }
    }
  }
}
</style>
