var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"download"},[_c('div',[_vm._m(0),_c('div',{staticClass:"button",on:{"click":_vm.goDownload}},[_c('img',{attrs:{"src":require("../assets/download.png")}}),_vm._v(" Download LoanNaira ")])])]),_c('img',{attrs:{"src":require("../assets/home_main.png")}})]),_vm._m(1),_c('div',{staticClass:"services"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("To Provide Better Financial Services")]),_c('div',{staticClass:"list"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"download",on:{"click":_vm.goDownload}},[_c('img',{attrs:{"src":require("../assets/download_2.png")}}),_vm._v(" Download LoanNaira ")])])])]),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tetx"},[_vm._v(" ALL YOUR LOAN"),_c('br'),_vm._v(" NEEDS IN ONE APP ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("Become a Member of LoanNaira")]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/member_list_1.png")}}),_vm._v(" Download ")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/member_list_2.png")}}),_vm._v(" Apply a loan ")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/member_list_3.png")}}),_vm._v(" Receive your loan ")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/member_list_4.png")}}),_vm._v(" Repay your loan ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/success.png")}}),_c('div',{staticClass:"text"},[_c('span',[_vm._v("High loan amount:")]),_vm._v(" Loan amount up to 50,000N ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/success.png")}}),_c('div',{staticClass:"text"},[_c('span',[_vm._v("Fast approval:")]),_vm._v(" Getting a loan in minutes ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/success.png")}}),_c('div',{staticClass:"text"},[_c('span',[_vm._v("Paperless apply:")]),_vm._v(" Application will be processed in minutes with no documentation ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"choose"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("Why Choose LoanNaira")]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/choose_list_1.png")}}),_vm._v(" Fast Loan approval ")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/choose_list_2.png")}}),_vm._v(" Dedicated Team ")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/choose_list_3.png")}}),_vm._v(" 24/7 Supports ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('img',{attrs:{"src":require("../assets/home_bottom.png")}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("Trusted by thousands of Nigerians")]),_c('div',{staticClass:"text"},[_vm._v("I am glad I found LoanNaira. I was able to get a loan in minutes to solve my business problem and avoid embarrassment. With LoanNaira I no longer need to beg friends and family for soft loan in moments of emergencies. Thank you LoanNaira.")])])])
}]

export { render, staticRenderFns }