<template>
  <div id="app">
    <div class="box">
      <m-header />
      <div class="content">
        <router-view/>
      </div>
      <m-footer />
    </div>
  </div>
</template>

<script>
import MHeader from '@/components/Header.vue'
import MFooter from '@/components/Footer.vue'
export default {
  components: {
    MHeader,
    MFooter
  }
}
</script>

<style lang="scss">
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.box {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
  }
}
</style>
