<template>
  <div class="footer">
    <div class="content">
      <img class="logo" src="../assets/logo.png" />
      <span class="name">LoanNaira</span>
      <img class="facebook" src="../assets/facebook.png" />
      <img class="email" src="../assets/email.png" />
      <span class="email_val">loannairaservice@gmail.com</span>
      <!-- <span class="service">Terms of Service</span>  -->
      <a href="https://loannaira.com/policy/privacy2.0.html"><span class="privacy">Privacy Policy</span></a>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped lang="scss">
.footer {
  min-height: 60px;
  background-color: #444444;
  width: 100%;
  line-height: 60px;
  .content {
    max-width: 1200px;
    margin: 0 auto;
  }
  img {
    vertical-align: middle;
  }
  .logo {
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }
  .name {
    font-size: 16px;
    color: #7CF259;
    font-weight: bold;
    margin-right: 80px;
  }
  .facebook {
    width: 20px;
    margin-right: 16px;
  }
  .email {
    width: 20px;
    margin-right: 6px;
  }
  .email_val {
    color: white;
    font-size: 16px;
    margin-right: 36px;
  }
  .service {
    color: white;
    font-size: 16px;
    margin-right: 36px;
  }
  .privacy {
    color: white;
    font-size: 16px;
  }
}
</style>